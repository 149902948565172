import { createStore } from "vuex";

import mainModule from "./modules/main";
const store = createStore({
  modules: {      
    suw:mainModule,
  },
});

export default store;
